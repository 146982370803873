<template>
  <div  class="minWidth1000">
    <custom-actions>
      <el-form inline label-width="100px">
        <el-form-item label="字典描述">
          <el-input v-model="searchForm.dicTypeDesc" placeholder="字典描述"/>
        </el-form-item>
        <el-form-item label="字典分类编码">
          <el-input v-model="searchForm.dicType" placeholder="字典分类编码"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <template #header>
        <div>
          <el-button type="primary" @click="showForm(null)">新增字典</el-button>
          <el-button type="primary" @click="syncRedis">同步至redis</el-button>
        </div>
      </template>
    </custom-actions>

    <el-card shadow="never" class="mt10">
      <el-table class="mt10" stripe size="mini" border v-loading="loading" :data="tableData">
        <el-table-column label="字典分类编码" prop="dicType"></el-table-column>
        <el-table-column label="字典分类描述" prop="dicTypeDesc"></el-table-column>
        <el-table-column label="字典编码" prop="dicCode"></el-table-column>
        <el-table-column label="字典值" prop="dicValue"></el-table-column>
        <el-table-column label="字典扩展编码" prop="dicExtCode"></el-table-column>
        <el-table-column label="排序" prop="orderNum"></el-table-column>
        <el-table-column label="操作">
          <template v-slot="{ row }">
            <el-button type="text" @click="showForm(row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="mt10"
        :current-page.sync="page"
        background
        layout="total, prev, pager, next"
        :total="total"
        @current-change="loadData">
      </el-pagination>
    </el-card>

    <el-dialog title="提示" :close-on-click-modal="false" :visible.sync="dialogVisible" width="400px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="字典分类编码" prop="dicType">
          <el-input v-model="form.dicType" placeholder="请输入字典分类编码"/>
        </el-form-item>
        <el-form-item label="字典分类描述" prop="dicTypeDesc">
          <el-input v-model="form.dicTypeDesc" placeholder="请输入字典分类描述"/>
        </el-form-item>
        <el-form-item label="字典编码" prop="dicCode">
          <el-input v-model="form.dicCode" placeholder="请输入字典编码"/>
        </el-form-item>
        <el-form-item label="字典值" prop="dicValue">
          <el-input v-model="form.dicValue" placeholder="请输入字典值"/>
        </el-form-item>
        <el-form-item label="字典扩展编码" prop="dicExtCode">
          <el-input v-model="form.dicExtCode" placeholder="请输入字典扩展编码"/>
        </el-form-item>
        <el-form-item label="排序" prop="orderNum">
          <el-input type="number" v-model="form.orderNum" placeholder="请输入排序号"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DictManage',
  data () {
    return {
      tableData: [],
      page: 1,
      total: 0,
      loading: false,
      form: {
        dicType: '', // 字典分类编码
        dicTypeDesc: '', // 字典分类描述
        pdicCode: '', // 父字典编码
        dicCode: '', // 字典编码
        dicValue: '', // 字典值
        dicExtCode: '', // 字典扩展编码
        orderNum: '' // 排序
      },
      rules: {
        dicType: [{ required: true, message: '请输入字典分类编码' }],
        dicTypeDesc: [{ required: true, message: '请输入字典分类描述' }],
        dicCode: [{ required: true, message: '请输入字典编码' }],
        dicValue: [{ required: true, message: '请输入字典值' }]
      },
      searchForm: {
        dicTypeDesc: null,
        dicType: null
      },
      dialogVisible: false
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url = this.form.dicId ? this.$apis.system.updateDic : this.$apis.system.insertDic
          this.$axios.post(url, this.form).then(() => {
            this.dialogVisible = false
            this.loadData()
          })
        }
      })
    },
    loadData () {
      this.$axios.get(this.$apis.system.selectDicByPage, {
        params: {
          page: this.page,
          ...this.searchForm
        }
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    onSearch () {
      this.page = 1
      this.loadData()
    },
    showForm (row) {
      this.dialogVisible = true
      this.$nextTick(() => {
        if (row) {
          this.form = JSON.parse(JSON.stringify(row))
          this.$refs.form.clearValidate()
        } else {
          this.form = {
            dicType: '', // 字典分类编码
            dicTypeDesc: '', // 字典分类描述
            pdicCode: '', // 父字典编码
            dicCode: '', // 字典编码
            dicValue: '', // 字典值
            dicExtCode: '', // 字典扩展编码
            orderNum: '' // 排序
          }
          this.$refs.form.resetFields()
        }
      })
    },
    syncRedis () {
      this.$confirm('确认同步至Redis嘛, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get(this.$apis.system.synchro).then(() => {
          this.$message({ type: 'success', message: '同步成功' })
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
